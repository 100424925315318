.page-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    
    /* Add this line */
}

.page-layout {
    display: flex;
    justify-content: center;
    align-items: right;
    /* Or height as needed for full page centering */
}

.grid-container {
    width: 100%;
}

.app-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    margin: 0 auto;
}

.modal-with-border {
    border: 5px solid #041E42; 
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); 
    padding: 20px; 
    position: fixed; 
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    background-color: white; 
    border-radius: 10px
  }
  
  .modal-button{
    padding: 15px 30px;  /* Increase padding as needed */ 
    font-size: 18px;    /* Increase font size if desired */
  }

  .footer-container {
    min-height: calc(100vh - 177px);
  }

  .footer--pin {
    position: relative;
    left: 0;
    bottom: 0;
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    font-size: 25px;
}